import React from 'react'

export default function Price(props) {
    const key = Object.keys(props.price)[0]
    const value = props.price[key]

    const doubleToCurrency = value => {
        return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRA'}).replace('BRA', 'R$')
    }

    return (
        <div className="price">
            <p>{key}</p>
            <span>{doubleToCurrency(value)}</span>
        </div>
    )
}