import React from 'react'

import Header from './components/header/Header'
import Menu from './components/menu/Menu'
import Localization from './components/localization/Localization'
import Footer from './components/footer/Footer'

import './App.scss'
import FloatingIcons from './components/floatingIcons/FloatingIcons'

export default function App() {
    const telephones = ['(11) 4013-2150', '(11) 91799-3363']
    
    return (
        <main>
            <Header phones={telephones} />
            <Menu />
            <Localization phones={telephones} />
            <Footer />
            <FloatingIcons />
        </main>
    )
}