import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone } from '@fortawesome/free-solid-svg-icons'

export default function Phone(props) {
    const link = `tel:+55${props.phone}`

    return (
        <a className="phone" href={link} title="ligar">
            <FontAwesomeIcon icon={faPhone} /> 
            <span>{props.phone}</span>
        </a>
    )
}