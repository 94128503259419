import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'

export default function FloatingIcons() {
    const whatsappLink = 'https://api.whatsapp.com/send?phone=5511996424271&text=Ol%C3%A1,%20gostaria%20de%20fazer%20um%20pedido'

    const whatsappStyle = {
        background: '#4FCE5D',
    }

    return (
        <section id="floating-icons">
            <a className="icon" style={whatsappStyle} href={whatsappLink} target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faWhatsapp} />
            </a>
        </section>
    )
}