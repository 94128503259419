import React from 'react'
import Phone from '../phone/Phone'

export default function Header(props) {
    const phoneElements = props.phones.map(phone => <Phone key={phone} phone={phone}/>) 

    return (
        <header>
            <div className="flex-container">
                <a href="/" title="home">
                    <img className='header-logo' src='/images/beto.png' alt="Logo beto's lanches itu" />
                </a>
                <div className="header-text">
                    <h1>Beto's Lanches</h1>
                    <div className="phones-container">
                        {phoneElements}
                    </div>
                </div>
            </div>
        </header>
    )
}