import React from 'react'
import Category from '../category/Category'

export default function Categories(props) {
    const {categories} = props
    const categorieElements = categories.map(cat => <Category key={cat.id} category={cat} handleClick={props.handleClick} />)

    return (
        <section className="categories-section">
            <div className="flex-container">
                {categorieElements}
            </div>
        </section>
    )
}