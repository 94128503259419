import React, {useState, useEffect} from 'react'

import Categories from '../categories/Categories'
import Product from '../product/Product'
//todo: define all at an api and call when component render
import { list } from '../../productList.mjs'

export default function Menu() {
    const [category, setCategory] = useState(list[0])

    useEffect(() => {
        // when component did mount, active state category
        const activeCategory = document.getElementById(category.name)
        activeCategory && activeCategory.classList.add('active') 
    }, [category.name]);

    const changeCategory = (event, categoryClicked) => {
        const target = event.currentTarget
        const list = document.getElementById('prod-list')
        
        const removeOldActiveClass = () => {
            if(category.name) {
                const oldActiveCategory = document.getElementById(category.name)
                oldActiveCategory && oldActiveCategory.classList.remove('active')
            }
        }

        const scrollIntoList = () => list && list.scrollIntoView({ behavior: 'smooth' })
        
        if (categoryClicked) {
            removeOldActiveClass()
            setCategory(categoryClicked)
            target.classList.add('active')
            scrollIntoList()
        }
    }

    const productElements = category.products.map(prod => <Product key={`${category.id}-${prod.id}`} product={prod} />)
    const optionElements  = category.options && category.options.map(prod => <Product key={`${category.id}-${prod.id}-options`} product={prod} />)

    return (
        <section className="menu">
            <div className="menu-container">
                <h1>Conheça o nosso cardápio</h1>
                <Categories categories={list} handleClick={changeCategory} />
            </div>

            
            <div id="prod-list" className="product-list">
                <div className="flex-container">
                    <div className="list-title">
                        <h2>{category.name}</h2>
                        <p>{category.description}</p>
                    </div>
                    {productElements}
                </div>
            </div>

            {optionElements && (
                <div id="prod-list" className="product-list">
                    <div className="flex-container" style={{paddingTop: 0}}>
                        <div className="options-title">
                            <h3>Opções Adicionais</h3>
                            <p>Complemente seu lanche com outras opções</p>
                        </div>
                        {optionElements}
                    </div>
                </div>
            )}

            <div className="download">
                <div className="flex-container">
                    <h3>Baixe o cardápio completo</h3>
                    <a href="/files/Cardapio-betos-lanches.pdf" download target="_blank" rel="noopener noreferrer" title="Baixar cardápio completo">Baixar</a>
                </div>
            </div>
        </section>
    )
}