import React from 'react'

export default function Category(props) {
    const {name, img} = props.category

    return (
        <div className="category" id={name} onClick={e => props.handleClick(e, props.category)}>
            <img src={img} alt={name} />
            <h3>{name}</h3>
        </div>
    )
}