import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopyright } from '@fortawesome/free-regular-svg-icons'

export default function Footer() {
    const year = new Date().getFullYear()
    return (
        <footer>
            <FontAwesomeIcon icon={faCopyright} /> {year} Lanchonete Daltoe LTDA - Todos os direito reservados 
        </footer>
    )
}